import React, { useState } from "react";
import { Link } from "react-router-dom";

import api from "lib/api";
import Spinner from "components/Spinner";
import Catalogue from "components/Catalogue/Catalogue";
import CatalogueProduct from "components/Catalogue/CatalogueProduct";

export default function ProductList() {
  const [values, setValues] = useState({ file: null });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.importProduct({
      file: values.file[0],
    });
    setLoading(false);
    if (!err) {
      setShowModal(false);
      window.location.reload();
    }
  };

  const form = (
    <>
      <form>
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
              File
            </label>

            <input
              name="file"
              type="file"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              onChange={onFileChange}
              accept=".xls, .xlsx"
            />
          </div>

          <button
            className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="submit"
            onClick={(e) => onSubmit(e)}
          >
            {loading && <Spinner size={4} />} Import
          </button>
        </div>
      </form>

      <div className="w-full lg:w-12/12 px-4">
        <div className="relative w-full mt-5">
          <ul className="text-gray-500 text-sm list-disc pl-4">
            <li>
              Please use the following template:
              <a
                className="underline ml-2 font-bold"
                href={"/assets/product_template.xlsx"}
                target={"_blank"}
                rel="noreferrer"
              >
                download
              </a>
            </li>
            <li>All fields are required</li>
            <li>Make sure that image field is public accessible</li>
            <li>Products with same "name" + "category" will be ignored</li>
          </ul>
        </div>
      </div>
    </>
  );

  const modal = (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-2/3 my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Import Gifts</h3>
              <button
                className="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold"
                onClick={() => setShowModal(false)}
              >
                <span className="text-black h-6 w-6 text-2xl block">×</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6">{form}</div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );

  const renderProduct = (product) => {
    return <CatalogueProduct key={`${product.id}`} product={product} />;
  };

  return (
    <>
      <div className="mt-4">
        <div className="w-full mb-4 p-4 bg-white rounded-lg">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-3 max-w-full flex-grow flex-1">
              <h3 className={"font-semibold text-lg text-slate-700"}>Gifts</h3>
            </div>
            <div>
              {/* <button
                className="bg-gray-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                Import
              </button> */}

              <Link to={`/products/new`}>
                <button
                  className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Add
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full">
          <Catalogue
            renderProduct={renderProduct}
            options={{
              categoryType: "all",
              contentBorder: true,
              cols: 5,
              showAllCategories: true,
              showKeyword: false,
              showSort: true,
              showSearch: true,
              showFilters: true,
            }}
          />
        </div>
      </div>

      {showModal ? modal : null}
    </>
  );
}
